
.cart .table{
    margin-bottom: 0px;
    border-bottom: 1px solid #fff;
}
.cart .table thead tr th{
    border-top:0px;
    font-size:16px;
    font-weight: bold;
    border-bottom:0px;
}
.cart .table thead tr td{
    padding-top:30px;
    padding-bottom: 30px;
    vertical-align: middle;
    align-self: center;
}
.cart .table tbody tr td .main .d-flex{
    padding-right:30px;
}
.cart .table tbody tr td .main .d-flex img{
    border: 2px solid #000;
    border-radius: 3px;
}
.cart .table tbody tr td .main .des{
    vertical-align: middle;
    align-self: center;
}
.cart .table tbody tr td .main .des p{
    margin-bottom:0px;
}
.cart .table tbody tr td h6{
    font-size:16px;
    color:#000;
    margin-bottom: 0px;
}
.cart .table tbody tr td .counter{
    margin-bottom:0px;
}
.counter i{
    border: 1px solid #000;
    padding: 7px;
    display: inline-block;
    position: relative;
}
.cart .table tbody tr td .counter input{
    width: 100px;
    padding-left:30px;
    height: 40px;
    outline:none;
    box-shadow: none;
}
.checkout ul{
    border: 2px solid #ebebeb;
    background: #f3f3f3;
    padding-left:25px;
    padding-right:25px;
    padding-top:5px;
    padding-bottom: 20px;
}
.checkout ul li{
    list-style: none;
    font-size:16px;
    font-weight: bold;
    color:#252525;
    text-transform: uppercase;
    overflow: hidden;
}
.checkout ul li.subtotal{
    font-weight: bold;
    text-transform: capitalize;
    border-bottom:1px solid #fff;
    padding-bottom: 14px;
}
.checkout ul li.subtotal span{
    font-weight: bold;
}
.checkout ul li.cart-total{
    padding-top: 10px
}
.checkout ul li.cart-total span{
    color:#000;
}
.checkout ul li span{
    float:right;
}
.checkout .proceed-btn{
    font-size:15px;
    font-weight: bold;
    color:#fff;
    background:#252525;
    text-transform: uppercase;
    padding:15px 25px 14px 25px;
    display: block;
    text-align: center;
}

.bg-gray {
    background: #aaa;
}

.cart-table-footer {
    background-color: #eee;
    font-weight: bold;
    font-size: 16px;
}