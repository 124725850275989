/* * {
    margin: 0px;
    padding: 0px;
    font-family: sans-serif;
    font-family: "Poppins" sans-serif;

    box-sizing: border-box;
    line-height: normal;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
} */

.menu a {
    width: 100%;
    font-size: 17px;
    color: #333;
}

.menu a:hover,
.menu .active {
    color: palevioletred;
}

.menu .i {
    margin-right: 10px;
    font-size: 18px;
}

.menu a:hover::before,
.menu .active::before {
    content: '';
    position: absolute;
    right: 0;
    width: 5px;
    height: 17px;
    background: #e2336b;


}

.activity {
    width: 100%;
    height: 50px;
}

.popular {
    background: #F3F6FD;

}


.main-post {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;


}

.likes {
    display: flex;
    align-items: center;
}

.likes i {
    font-size: 20px;
    margin-left: 10px;
}

.likes span {
    font-size: 14;
    margin-left: 7px;
}

.box {
    width: 350px;
    height: 250px;
    background: #FFFFFF;

}

.button {
    color: black;
    background: wheat;
    padding: 15px 154px;
    font-size: 30px;
}

.header h1 {
    color: white;
    padding: 15px;
}

.body {
    flex: 1;
    color: white;
    /* background-color: rgba(0, 0, 0, 0.7); */
    padding: 20px 30px;
}

.message {
    background-color: dodgerblue;
    padding: 10px;
    color: white;
    width: fit-content;
    border-radius: 10px;
    margin-bottom: 0px;
}

.main {
    margin-right: 40px;
}

.user_message {
    margin-left: auto;
    background-color: white;
    color: black;
}

.footerr form {
    display: flex;
}

.App {
    text-align: center;
  }
  
  .App-logo {
    height: 118px;
    display: inline;
  }
  .logo-font {
    font-size: 30px;
    font-weight: bold;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
form input {
    flex: 3;
    height: 40px;
    border: none;
    outline: none;
    padding-right: 1px;
    font-size: 16px;
}

form button {
    width: 200px;
    height: 35px;
    font-size: none;
    border: none;
    outline: none;
    background-color: dodgerblue;
    color: white;
    cursor: pointer;
}

form button {
    background-color: blue;
    transition: 0.2s ease;
}


.search-box {
    border: none;
    background: transparent;
    margin: 0;
    padding: 7px 8px;
    font-size: 14px;
    color: inherit;
    border: 1px solid transparent;
    border-radius: inherit;
  }
  
  .search-button {
    text-indent: -999px;
    overflow: hidden;
    width: 40px;
    padding: 0;
    margin: 0;
    border: 1px solid transparent;
    border-radius: inherit;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' className='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat center;
    cursor: pointer;
    opacity: 0.7;
  }
  
  .product-card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 40%;
  }
  
  .product-card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  
  form {
    color: #555;
    /* display: flex; */
    padding: 2px;
    /* border: 1px solid currentColor;
    border-radius: 5px; */
    margin: 0 0 10px;
  }
  
  /* input[type="search"] {
    border: none;
    background: transparent;
    margin: 0;
    padding: 7px 8px;
    font-size: 14px;
    color: inherit;
    border: 1px solid transparent;
    border-radius: inherit;
  } */
  
  input[type="search"]::placeholder {
    color: #bbb;
  }
  
  /* button[type="submit"] {
    text-indent: -999px;
    overflow: hidden;
    width: 40px;
    padding: 0;
    margin: 0;
    border: 1px solid transparent;
    border-radius: inherit;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' className='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat center;
    cursor: pointer;
    opacity: 0.7;
  }
  
  button[type="submit"]:hover {
    opacity: 1;
  }
   */
  /* button[type="submit"]:focus,
  input[type="search"]:focus {
    box-shadow: 0 0 3px 0 #161617;
    border-color: #131415;
    outline: none;
  }
   */
  form.nosubmit {
   border: none;
   padding: 0;
   width: 400px;
   display: inline-block;
  }
  
  input.nosubmit {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 5px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    border: none;
    width: 100%;
    padding: 9px 4px 9px 40px;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' className='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
  }
  .w3-bar-item {
    margin-bottom: 20px;
  }
  
  .plus-button {
    border: none;
    padding: 6px 15px;
    border-radius: 50%;
    font-size: 35px;
    display: block;
    text-align: center;
    margin: auto;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 5px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 20px;
  }
  
  
  .profile-sec {
    text-align: left;
  }
  .profile-pic {
    width: 120px;
    height: 115px;
    border-radius: 65px;
    margin-left: 65px;
    border: #f9f9f9 4px solid;
    box-shadow: rgb(0 0 0 / 20%) 0px 4px 8px 0px, rgb(0 0 0 / 19%) 0px 6px 20px 0px;
  }

  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }