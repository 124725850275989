
.post-card {
    background: #fff;
    height: 300px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 5px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    width: 225px;
  }
  
  .post-card:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
  }


  .brandName {
    color: #555;
  }

  .productName {
    color: #333;
    font-size: 16px;
    font-weight: bold;
  }

  .price {
    color: orange;
    font-size: 14px;
    font-weight: bold;
    display: block;
  }

  .btn-cart {
    background-color: #3197e91a;
    border: 1px solid #3197e990;
    color: #3197e9;
    font-weight: bold;
    padding: 5px 10px;
    margin: 10px 5px 15px 5px;
    font-size: 11px;
  }

  .btn-buy {
    background-color: #f26e211a;
    border: 1px solid #f26e2190;
    color: #f26e21;
    font-weight: bold;
    padding: 5px 10px;
    margin: 10px 5px 15px 5px;
    font-size: 11px;
  }
  .chat-icon {
    display: block;
    margin-top: 15px;
    color: black;
    font-weight: bold;
  }

  .product-img {
    position: relative;
    overflow: hidden;
    margin: auto;
    height: 200px;
    padding-top: 10px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .product-img img {    
      height: 100%;
  }

  .product-card-body {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .ribbon {
    position: relative;
    text-transform: uppercase;
    color: white;
  }
  .card-wrap {
    width: 100%;
    height: 188px;
    position: absolute;
    top: -8px;
    left: 8px;
    overflow: hidden;
  }
  .card-wrap:before, .card-wrap:after {
    content: ""; 
    position: absolute;
  }
  .card-wrap:before {
    width: 40px;
    height: 8px;
    right: 100px;
    background: #4D6530;
    border-radius: 8px 8px 0px 0px;
  }
  .card-wrap:after {
    width: 8px;
    height: 40px;
    right: 0px;
    top: 100px;
    background: #4D6530;
    border-radius: 0px 8px 8px 0px;
  }
  .ribbon6 {
    width: 200px;
    height: 40px;
    line-height: 40px;
    position: absolute;
    top: 30px;
    right: -50px;
    z-index: 2;
    overflow: hidden;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border: 1px dashed;
    box-shadow:0 0 0 3px #57DD43,  0px 21px 5px -18px rgba(0,0,0,0.6);
    background: #57DD43;
    text-align: center;
  }
  .ribbon2 {
    width: 60px;
    padding: 10px 0;
    position: absolute;
    top: -6px;
    left: 25px;
    text-align: center;
    border-top-left-radius: 3px;
    background: #F47530;
    z-index: 10;
   }
   .ribbon2:before {
    height: 0;
    width: 0;
    right: -5.5px;
    top: 0.1px;
    border-bottom: 6px solid #8D5A20;
    border-right: 6px solid transparent;
   }
   .ribbon2:before, .ribbon2:after {
     content: "";
     position: absolute;
   }
   .ribbon2:after {
     height: 0;
     width: 0;
     bottom: -29.5px;
     left: 0;
     border-left: 30px solid #F47530;
     border-right: 30px solid #F47530;
     border-bottom: 30px solid transparent;
   }

@media screen and (max-width: 991px) {
  .post-card {
    width: 170px;
    height: 275px;
  }
  .product-img {
    height: 160px;
  }
}