.w-20 {
  width: 20px !important;
}

#main-container {
  margin-left: 275px;
}
#sidebar {
  width: 275px;
  margin-bottom: 30px;
}

#sidebar-btn {
  display: none;
}

#reader img {
  width: 100px;
  margin: auto;
}

.login-card {
  width: 35%;
}
.r-width {
  width: 60%;
}

#sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  display: none;
  margin-left: 50px;
  cursor: pointer;
}

/* start of phone styles */
/* On screens that are less than 479px, hide the sidebar */
@media screen and (max-width: 1366px) {
  .login-card {
    width: 70%;
  }
  .r-width {
    width: 70%;
  }
}

@media screen and (max-width: 991px) {
  #sidebar-btn {
    display: block;
  }
  #sidebar {
    display: none;
  }
  #main-container {
    margin-left: 0%;
  }
  .logo-font {
    font-size: 20px;
  }
  .menu a {
    font-size: 15px;
  }
  .login-card {
    width: 90%;
  }
  .r-width {
    width: 90%;
  }

  #sidebar .closebtn {
    display: block;
  }
}
