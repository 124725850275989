
text-color {
    color: #0093c4;
}

.main-description .category {
    text-transform: uppercase;
    color: #0093c4;
}

.main-description .product-title {
    font-size: 2.5rem;
}

.old-price-discount {
    font-weight: 600;
}

.new-price {
    font-size: 2rem;
}

.details-title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.2rem;
    color: #757575;
}

.buttons .block {
    margin-right: 5px;
}

.quantity {
    padding-top: 10px;
    padding-left: 10px;
}
.quantity input {
    border-radius: 0;
    height: 40px;
    min-height: auto;
}


.custom-btn {
    text-transform: capitalize;
    background-color: #0093c4;
    color: white;
    width: 150px;
    height: 40px;
    border-radius: 0;
}

.custom-btn:hover {
    /* background-color: #0093c4 !important; */
    font-size: 18px;
    color: white !important;
}

.questions .icon i {
    font-size: 2rem;
}

.questions-icon {
    font-size: 2rem;
    color: #0093c4;
}


/* zoom */
.slideshow-items {
    width: 500px;
  }
  .slideshow-thumbnails {
    width: 100px;
  }
  #slideshow-items-container { display: inline-block; position: relative; }
  
  #lens { background-color: rgba( 233, 233, 233, 0.4 ) }
  #lens, #result { position: absolute; display: none; z-index: 1; }
  .slideshow-items { display: none; }
  .slideshow-items.active { display: block; }
  .slideshow-thumbnails { opacity: 0.5; }
  .slideshow-thumbnails.active { opacity: 1; }
  #lens, .slideshow-items, .slideshow-thumbnails, #result { border: solid var(--light-grey-2) 1px; }
  